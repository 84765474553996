import {
  ContentImage,
  ContentListOfProducts,
  ContentProduct,
  TitleListOfProducts,
  WrapperListOfProducts,
} from './ListOfProducts.style'
import { IPlacement, isCMProductList } from '@typesApp/cmsPlacement/Placement'
import { ICMProductList } from '@typesApp/cmsPlacement/CMProductList'

import { CmsAnchorMegaMenu } from '@components/Cms/CmsComponents-CSS/CmsAnchorMegaMenu'
import ProductImage, { PDP_PRODUCT_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE } from '../../ProductImage/ProductImage'
import React from 'react'
import { getModelName } from '../../../utils/productAttributes'
import { openDrawerMegaMenuSelector } from '../../../features/ui/selector'
import { useSelector } from 'react-redux'
import { formatElementString } from '@foundation/analytics/tealium/lib'

const formatListOfProducts = (data: ICMProductList['productData']): ICMProductListFormatted[] => {
  const formattedData = data.map(el => {
    return {
      id: el?.id || '',
      modelName: getModelName(el) || '',
      formattedUrl: el?.cluster?.[0].seo?.href! || '',
      attachments: el?.cluster?.[0].attachments! || [],
    }
  })

  return formattedData || []
}

export interface ICMProductListFormatted {
  id: string
  modelName: string
  formattedUrl: string
  attachments: any[]
}
const ListOfProducts: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const listOfProductItems = placement?.items?.find(isCMProductList)
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''

  if (!listOfProductItems) return null
  const title = listOfProductItems.teaserTitle || ''
  const productListFormatted = formatListOfProducts(listOfProductItems.productData || [])

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }
  const { categoryName } = useSelector(openDrawerMegaMenuSelector)
  return (
    <WrapperListOfProducts {...metaData}>
      <TitleListOfProducts>{title}</TitleListOfProducts>
      <ContentListOfProducts>
        {productListFormatted.map(product => (
          <ContentProduct key={product.id}>
            <ContentImage>
              <ProductImage
                attachments={product.attachments}
                draggable={false}
                sequence={PDP_PRODUCT_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE}
                usage={'PLP'}
              />
            </ContentImage>
            <CmsAnchorMegaMenu
              elementId={`X_X_MainNav_${categoryName?.toUpperCase()}_${product.modelName.toUpperCase()}`}
              data-testid={`mainNav${formatElementString(categoryName ?? '')}${formatElementString(product?.modelName ?? '')}`}
              href={product.formattedUrl}
            >
              {product.modelName}
            </CmsAnchorMegaMenu>
          </ContentProduct>
        ))}
      </ContentListOfProducts>
    </WrapperListOfProducts>
  )
}

export default ListOfProducts
