import React from 'react'
import { CSSProperties } from 'styled-components'
import { BrandImg, WrapperBrandIcon } from './BrandIcon.style'
import brandListClearly from './brandListClearly'

export interface BrandIconProps {
  name: string
  customStyle?: CSSProperties
}

const BrandIcon: React.FC<BrandIconProps> = ({ name, customStyle }) => {
  const brand = brandListClearly.find(brand => brand.name.toLowerCase() === name?.toLowerCase())
  if (!brand) return null
  const brandName = brand.name

  return (
    <WrapperBrandIcon style={customStyle}>
      <BrandImg src={brand.logo} alt={brandName} loading="lazy" crossOrigin="anonymous" />
    </WrapperBrandIcon>
  )
}
export default BrandIcon
