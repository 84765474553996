import React from 'react'
import { IPlacement, isCMExternalChannel, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ContentListOfBrands, TitleListOfBrands, WrapperListOfBrands } from './ListOfBrands.style'
import { CmsAnchorMegaMenu } from '@components/Cms/CmsComponents-CSS/CmsAnchorMegaMenu'
import { openDrawerMegaMenuSelector } from '../../../features/ui/selector'
import { useSelector } from 'react-redux'
import { formatElementString } from '@foundation/analytics/tealium/lib'

const ListOfBrands: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const teaserItemColl = placement?.items?.filter(isLXTeaser)
  const listOfbrandsItemsColl = placement?.items?.filter(isCMExternalChannel).map((element, index) => {
    let teaserTitle = teaserItemColl[index].teaserTitle1
    return { ...element, teaserTitle }
  })

  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }
  const { categoryName } = useSelector(openDrawerMegaMenuSelector)

  return (
    <>
      {listOfbrandsItemsColl.map(x => {
        return (
          <WrapperListOfBrands key={x.externalId} {...metaData}>
            <TitleListOfBrands>{x.title}</TitleListOfBrands>
            <ContentListOfBrands>
              {x.brandData?.map(brand => (
                <React.Fragment key={brand.id}>
                  <CmsAnchorMegaMenu
                    elementId={`X_X_MainNav_${categoryName?.toUpperCase()}_${brand.name.toUpperCase()}`}
                    data-testid={`mainNav${formatElementString(categoryName ?? '')}${formatElementString(brand?.name ?? '')}`}
                    href={brand.formattedUrl}
                  >
                    {brand.name}
                  </CmsAnchorMegaMenu>
                </React.Fragment>
              ))}
            </ContentListOfBrands>
          </WrapperListOfBrands>
        )
      })}
    </>
  )
}

export default ListOfBrands
