import {
  ContentCtaCategories,
  ContentIconsCategories,
  IconsLinkCategories,
  TitleCategories,
  WrapperCategoriesWithIcon,
} from './CategoriesWithIcon.style'
import { IPlacement, isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'
import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'

import AvatarWithLink from './components/AvatarWithLinks'
import CtaMegaMenu from '../../CtaMegaMenu'
import React from 'react'
import useBreakpoints from '../../../hooks/useBreakpoints'

type IExternalCategories = (ICMExternalPage | ICMExternalChannel)[]

const CategoriesWithIcon: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const { isDesktop } = useBreakpoints()
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const collectionItem = placement?.items?.find(isCMCollection)
  const teaserItem = placement?.items?.find(isLXTeaser)

  if (!collectionItem) return null
  const title = collectionItem.collectionTitle || ''
  const collectionTeasableItems = (collectionItem.teasableItems as IExternalCategories) || []
  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <WrapperCategoriesWithIcon {...metaData}>
      {isDesktop && <TitleCategories>{title}</TitleCategories>}
      <ContentIconsCategories>
        {collectionTeasableItems.map((teasableItem, index) => (
          <IconsLinkCategories key={`cms-content__${viewType}--item-${index}`}>
            <AvatarWithLink teasableItem={teasableItem} testIndex={index.toString()} />
          </IconsLinkCategories>
        ))}
      </ContentIconsCategories>
      {isDesktop && (
        <ContentCtaCategories>
          {teaserLXCallToActionSettings.map((actionSettings, index) => (
            <CtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              dataElementId={`X_X_Categories_With_Icon_Placement_Banner_${actionSettings.callToActionText?.replaceAll(
                ' ',
                '_'
              )}_CTA${index}`}
              actionSettings={actionSettings}
            />
          ))}
        </ContentCtaCategories>
      )}
    </WrapperCategoriesWithIcon>
  )
}

export default CategoriesWithIcon
