import React from 'react'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'
import { useDispatch } from 'react-redux'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '../../../../features/ui/action'
import BrandIcon from '../../../BrandIcon'
import styled from '@mui/material/styles/styled'
import { Link } from '@components/common/Link/Link'

const BrandLink = styled(Link, {
  name: 'ListOfBrandIcon',
  slot: 'BrandLink',
  shouldForwardProp: prop => prop !== 'width',
})<{ width?: string }>(({ theme, width }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: width ? width : '100%',
  margin: 0,
  padding: theme.spacing(4),
}))

const BrandIconLink: React.FC<{
  teasableItem: ICMExternalChannel
  width?: string
  dataTestid?: string
}> = ({ teasableItem, width }) => {
  const dispatch = useDispatch()
  const { basePath } = useStoreIdentity()

  const brandName = teasableItem?.externalId?.split('_').pop() || ''
  const formattedUrl = teasableItem?.formattedUrl || ''
  const link = `${basePath}${formattedUrl || ''}`

  const placementCounter = teasableItem.placementCounter
  const placementIndex = placementCounter?.placementIndex ?? 0
  const tabIndex = placementCounter?.tabIndex ?? 0
  const tileIndex = placementCounter?.tileIndex ?? 0

  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))
  }
  return (
    <BrandLink
      data-element-id={`${placementIndex}Placement${typeof tabIndex == 'number' ? `_Tab${tabIndex}` : ''}_Tile${tileIndex}_IMG`}
      data-description={brandName}
      href={link}
      onClick={closeMegaMenu}
      width={width}
      data-testid={`mainNavBrand${brandName}`}
    >
      <BrandIcon name={brandName} />
    </BrandLink>
  )
}

export default BrandIconLink
